import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    justify: 'space-between',
    pb: '2',
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'md',
    color: 'beta.600',
    fontWeight: 'medium',
  },
  headerIconButton: {
    color: 'beta.600',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerVsBox: {
    color: 'beta.600',
  },
  bodyStack: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    px: [0, 2, null, '4'],
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    color: 'beta.500',
    h: '1.25em',
    ml: '1',
    w: 'auto',
  },
  scrollButtonGroup: {
    variant: 'filter',
    bg: 'none',
  },
  toMarketButton: {
    borderColor: '#F5CB23',
    color: 'white',
    _active: {
      bgGradient: 'linear(to-b, #F5CB23, #F5CB23)',
      color: 'alpha.800',
    },
    _hover: {
      color: '#F5CB23',
    },
    _last: { mr: 2 },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    mx: '-1',
  },
  accordionStack: {
    px: '1',
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    borderRadius: 'md',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    overflow: 'hidden',
    borderWidth: '1px',
    borderColor: 'gray.400',
  },
  accordionButton: {
    fontSize: 'xs',
    fontWeight: 'bold',
    p: '2',
    pos: 'relative',
    textAlign: 'left',
    bg: 'beta.500',
    _hover: {
      bg: 'beta.500',
    },
    _after: {
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      _after: { opacity: '1' },
    },
  },

  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLayoutIcon: {
    color: 'delta.700',
    fontSize: 'lg',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    color: 'white',
    fontSize: '2xl',
  },
  marketLayoutAccordionPanel: {
    bg: 'blackAlpha.100',
    p: '0',
  },
  cancelButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    px: '3',
  },
  clearButton: {
    fontFamily: 'roboto',
    fontSize: 'xs',
    ml: '3',
    px: '3',
  },
  countdown: {
    sx: {
      textTransform: 'uppercase',
      '&&&.state-prior': {
        p: 0,
        lineHeight: 'initial',
      },
    },
  },
  sgmLogoV2: {
    color: 'white',
    w: ['3.5em', null, null, '3em'],
    h: ['1.3em', null, null, '1.4em'],
  },
  wrapperScrollSgm: { w: '100%', maxW: '100%', gap: '1' },
  scrollButtonGroupV2: {
    bg: 'transparent',
    variant: 'unstyled',
    flex: 1,
    position: 'relative',
    minH: '34px',
  },
  accordionItemActive: {
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
  },
  toMarketButtonV2: {
    color: 'white',
    fontSize: 'xs',
    textTransform: 'uppercase',
    minW: 'fit-content',
    fontWeight: 'bold',
    cursor: 'pointer',
    _active: {
      bg: 'none',
    },
    sx: {
      '&&': {
        px: 2,
        ml: 0,
      },
      '&[data-active]': {
        color: '#F5CB23',
        bg: 'none',
      },
      '.chakra-button__icon': {
        margin: 0,
      },
    },

    _last: { mr: 2 },
  },
  sgmBtn: {
    sx: {
      svg: {
        color: 'white',
        fill: 'white',
      },
    },
  },
  sgmBtnActive: {
    boxShadow: '0px 2px 4px 0px #00000040 inset, 0px 1px 0px 0px #FFFFFF1A',

    sx: {
      svg: {
        color: 'theta.50',
        fill: 'theta.50',
      },

      '&&': {
        bg: 'beta.600',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.50) inset',
      },
    },

    _after: { display: 'none' },
  },
};

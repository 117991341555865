/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryClient } from '@tanstack/react-query';
import { AppDispatch } from '../../../redux/store';
import {
  EBetSubmissionRejectionReason,
  TBetSlipBetAPIInput,
  TBetSubmissionConfirmation,
  EBetSlipBetSubmissionType,
  TBetSlipBetMulti,
  TBetSlipBet,
  EBetSubmissionConfirmationStatus,
  EOddsChangeDirection,
  TBetSlipBetSRMulti,
  TBetSlipBetSingle,
} from './Betslip.types';
import { apiGetRequest, apiPostRequest } from '../../../lib/api/api';
import {
  clearNonStakedBets,
  setDisplayOddsChangeBanner,
  setDisplayEventsClosedBanner,
  setGeneralError,
  setHasSufficientFunds,
  updateBetsWithConfirmations,
  setBonusChanceModal,
} from '../../../redux/Betslip.slices';
import { getPunterAccountOverview } from '../../../views/account/services/account.actions';
import { serializeBetsForAPI } from './Betslip.utils';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { postMobileAppMessage } from '../../../mobileapp/mobileapp.utils';
import { TSelection } from '@/views/races/bets/SRMulti/components/Selections/services/Selections.hooks';
import { TMoMSchema, getBetSlipStoreActions } from '@/store/BetSlipStore';
import { keys as apiKeys } from '@/api/api.keys';
import {
  isPropositionClosedForSRMulti,
  normaliseBetsForSubmission,
} from './utils/utils';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { TMultiMoMBet } from '../components/Modal/MoM/types';

const storePendingBetsLocally = (newPendingBets: TBetSlipBet[]) => {
  // Non-multi bets
  const pendingBets = window.localStorage.getItem('pendingBets');
  const parsedPendingBets: TBetSlipBet[] = pendingBets
    ? JSON.parse(pendingBets)
    : [];
  const dataToBeStored = newPendingBets
    .filter(
      (bet) =>
        !parsedPendingBets.some((ppb) => ppb.request_id === bet.request_id)
    )
    .map((pb) => ({
      ...pb,
      confirmation: {
        status: pb.confirmation?.status,
        request_id: pb.confirmation?.request_id,
      },
    }));
  window.localStorage.setItem(
    'pendingBets',
    JSON.stringify([...parsedPendingBets, ...dataToBeStored])
  );
};

/**
 * TODO: Rebuild this without redux
 */
/* Submits the betslip to the API */
export const submitBetSlip = createAsyncThunk<
  void,
  {
    bets: TBetSlipBet[];
    queryClient: QueryClient;
    hasLD: boolean | undefined;
    moMBet: TMoMSchema;
  },
  { dispatch: AppDispatch }
>(
  'betSlip/submitBetSlip',
  async ({ bets, queryClient, hasLD, moMBet }, thunkAPI) => {
    try {
      const raceIdsInStorage: string[] = JSON.parse(
        localStorage.getItem('@@/BC-RACE_IDS') ?? '[]'
      );
      const raceIds = new Set(raceIdsInStorage);

      thunkAPI.dispatch(setBonusChanceModal(null));

      const { updateBet: _updateBet, updateMoMBet } = getBetSlipStoreActions();

      // Only send bets that have notified user of OddsChange OR InsufficientFunds OR have NOT been submitted yet
      const betsToSubmit = serializeBetsForAPI(
        bets
          ?.filter(
            (bet) =>
              Number(bet.stake) > 0 &&
              (!bet.confirmation ||
                bet.confirmation?.rejection_reason ===
                  EBetSubmissionRejectionReason.OddsChange ||
                bet.confirmation?.rejection_reason ===
                  EBetSubmissionRejectionReason.FundTransferFail ||
                bet.confirmation?.rejection_reason ===
                  EBetSubmissionRejectionReason.InsufficentFund ||
                (bet.type === EBetSlipBetSubmissionType.Multi &&
                  bet.confirmation?.rejection_reason ===
                    EBetSubmissionRejectionReason.PropositionClosed))
          )
          .filter(({ id }) => !raceIdsInStorage.some((raceId) => raceId === id))
      );

      const newBets = betsToSubmit.filter(Boolean).map((bet) => {
        if (bet?.type === EBetSlipBetSubmissionType.SRMulti) {
          const b = bet as unknown as TBetSlipBetSRMulti;
          return {
            ...b,
            selection: b.selection.reduce(
              (acc: number[][], cur: TSelection[]) => {
                const sel = cur.map((item) => item.runner_number);
                acc.push(sel);
                return acc;
              },
              [] as number[][]
            ),
          };
        }

        if (
          ['tote_single_mid', 'tote_single_best'].includes(
            bet?.price_type ?? ''
          ) &&
          bet?.odds
        ) {
          // eslint-disable-next-line no-param-reassign
          delete bet.odds;
        }

        return bet;
      });

      const confirmations = await apiPostRequest<
        TBetSubmissionConfirmation[],
        (TBetSlipBetAPIInput | null)[]
      >(
        FEATURE_FLAGS.REACT_APP_MOM_ENABLED
          ? '/punter/bet-slip/v3/submit'
          : '/punter/bet-slip/v2/submit',
        FEATURE_FLAGS.REACT_APP_MOM_ENABLED
          ? {
              channel: 'DesktopBrowser',
              bets: [
                ...newBets.map(normaliseBetsForSubmission).filter(Boolean),
                ...(moMBet ? [normaliseBetsForSubmission(moMBet)] : []),
              ],
            }
          : newBets
      );

      confirmations.forEach((confirmation) => {
        if (
          confirmation.request_id &&
          confirmation.status === EBetSubmissionConfirmationStatus.Pending
        )
          raceIds.add(confirmation.request_id);
      });

      const raceIdsAsArray = Array.from(raceIds);
      localStorage.setItem('@@/BC-RACE_IDS', JSON.stringify(raceIdsAsArray));

      /** Variable to identify when a Bet Submission has been rejected */

      // Map the response confirmations to the bets
      const updatedBetsWConfirmation = await Promise.all(
        bets?.map(async (bet) => {
          const confirmation = confirmations.find(
            (_) => _.request_id === bet.request_id
          );

          // Confirmation found & the odds have changed. Update the odds & property
          if (confirmation?.changed_odds?.length) {
            thunkAPI.dispatch(setDisplayOddsChangeBanner(true));

            if (
              !FEATURE_FLAGS.REACT_APP_MOM_ENABLED &&
              bet.type === EBetSlipBetSubmissionType.Multi
            ) {
              const multiBet = bet as TBetSlipBetMulti;
              const updatedLegs = multiBet.legs
                .filter((leg) =>
                  betsToSubmit
                    .find((b) => b?.request_id === multiBet.request_id)
                    ?.legs?.some((l) => l.proposition_id === leg.proposition_id)
                )
                .map((prop) => {
                  const propOddsChanged = confirmation.changed_odds?.find(
                    (ch) => ch.proposition_id === prop.proposition_id
                  );
                  if (propOddsChanged) {
                    return {
                      ...prop,
                      odds: propOddsChanged.new_odds,
                      oddsChangeDirection:
                        propOddsChanged.new_odds > prop.odds
                          ? EOddsChangeDirection.Increase
                          : EOddsChangeDirection.Decrease,
                    };
                  }
                  return prop;
                });

              const newPotentialReturns =
                Number(bet.stake) *
                  updatedLegs.reduce(
                    (acc, curr) => (!curr.eventClosed ? acc * curr.odds : acc),
                    1
                  ) -
                (bet.is_bonus_bet ? Number(bet.stake) : 0);

              return {
                ...bet,
                legs: updatedLegs,
                potential_returns: newPotentialReturns,
                confirmation,
              };
            }

            // Single bets should only have one element in changed_odds
            const newOdds = confirmation?.changed_odds[0].new_odds;
            const newPotentialReturns =
              newOdds * Number(bet.stake) -
              (bet.is_bonus_bet ? Number(bet.stake) : 0);

            return {
              ...bet,
              odds: newOdds,
              potential_returns: newPotentialReturns,
              confirmation,
            };
          }

          // FundTransferFail
          if (
            confirmation?.rejection_reason ===
              EBetSubmissionRejectionReason.FundTransferFail &&
            confirmation?.is_betslip_rejection === false
          ) {
            try {
              // todo: hit the endpoint and check if the bet was placed
              const requestConfirmation: TBetSubmissionConfirmation[] =
                await apiGetRequest(
                  `/punter/bet-slip/exists?bet_id=${bet.request_id}`
                );

              if (requestConfirmation.length > 0)
                return {
                  ...bet,
                  confirmation:
                    requestConfirmation.length > 0
                      ? requestConfirmation[0]
                      : confirmation,
                };
            } catch (e) {
              return {
                ...bet,
                confirmation,
              };
            }
          }

          if (
            confirmation?.rejection_reason ===
              EBetSubmissionRejectionReason.PropositionClosed &&
            !!confirmation.closed_propositions?.length &&
            bet.type === EBetSlipBetSubmissionType.Multi
          ) {
            thunkAPI.dispatch(setDisplayEventsClosedBanner(true));
            const multiBet = bet as TBetSlipBetMulti;
            const closedProps = confirmation.closed_propositions;

            const updatedLegs = multiBet.legs.map((prop) => ({
              ...prop,
              eventClosed: closedProps.includes(prop.proposition_id),
            }));
            const newOdds =
              updatedLegs.reduce(
                (acc, curr) => (!curr.eventClosed ? acc * curr.odds : acc),
                1
              ) || 0;
            const newPotentialReturns =
              newOdds * Number(bet.stake) -
              (bet.is_bonus_bet ? Number(bet.stake) : 0);

            return {
              ...bet,
              legs: updatedLegs,
              potential_returns: newPotentialReturns,
              confirmation,
            };
          }

          // Confirmation found - update the property
          if (confirmation) {
            let reducedStakeConfig = {};

            if (confirmation.reduced_stake) {
              const singleBet = bet as TBetSlipBetSingle;
              const reducedStake = confirmation?.reduced_stake / 100;
              const newStake = confirmation?.reduced_stake
                ? Number(bet.stake) - reducedStake
                : Number(bet.stake);
              const potentialReturn =
                singleBet.odds * newStake -
                (bet.is_bonus_bet ? Number(bet.stake) : 0);

              reducedStakeConfig = {
                stake: newStake,
                potential_returns: potentialReturn,
              };
            }

            return {
              ...bet,
              ...reducedStakeConfig,
              confirmation,
            };
          }

          return bet;
        })
      );

      if (moMBet) {
        const { requestId } = moMBet;
        const confirmation = confirmations?.find(
          ({ request_id }) => request_id === requestId
        );
        if (confirmation) {
          // rejected reason
          if (confirmation.rejection_reason) {
            // each case here corresponds to a different rejection reason
            switch (confirmation.rejection_reason) {
              case EBetSubmissionRejectionReason.OddsChange: {
                const { odds } = moMBet;
                const newOdds = confirmation.changed_odds
                  ? confirmation.changed_odds[0].new_odds
                  : odds;
                const haveOddsDecreased = newOdds < odds;
                const oddsObj = {
                  odds: newOdds,
                  betOddsChange: haveOddsDecreased
                    ? EOddsChangeDirection.Decrease
                    : EOddsChangeDirection.Increase,
                  betRejectionReason: EBetSubmissionRejectionReason.OddsChange,
                };
                if (haveOddsDecreased)
                  thunkAPI.dispatch(setDisplayOddsChangeBanner(true));
                updateMoMBet({ ...oddsObj });

                break;
              }
              case EBetSubmissionRejectionReason.MaxStakeExceeded: {
                const { max_stake_limit: maxStakeLimit } = confirmation;
                updateMoMBet({
                  maxStakeLimit,
                  betRejectionReason:
                    EBetSubmissionRejectionReason.MaxStakeExceeded,
                  betSubmissionStatus:
                    EBetSubmissionConfirmationStatus.Rejected,
                });
                break;
              }
              case EBetSubmissionRejectionReason.PropositionClosed: {
                const closedPropositionIds =
                  confirmation.closed_propositions ?? [];
                thunkAPI.dispatch(setDisplayEventsClosedBanner(true));

                switch (moMBet.type) {
                  case 'Blended': {
                    const { legs } = moMBet;

                    const newLegs = legs.map((leg) => {
                      if (closedPropositionIds.includes(leg.propositionId)) {
                        return {
                          ...leg,
                          propositionClosed: true,
                        };
                      }
                      return leg;
                    });

                    updateMoMBet({
                      legs: newLegs,
                      betRejectionReason:
                        EBetSubmissionRejectionReason.PropositionClosed,
                      betSubmissionStatus:
                        EBetSubmissionConfirmationStatus.Rejected,
                      isClosed: true,
                    });
                    break;
                  }
                  case 'SGMulti': {
                    const { legs } = moMBet;

                    const newLegs = legs.map((leg) => {
                      if (closedPropositionIds.includes(leg.propositionId)) {
                        return {
                          ...leg,
                          propositionClosed: true,
                        };
                      }
                      return leg;
                    });

                    updateMoMBet({
                      legs: newLegs,
                      betRejectionReason:
                        EBetSubmissionRejectionReason.PropositionClosed,
                      isClosed: true,
                    });
                    break;
                  }
                  case 'Multi': {
                    const updatedMomBetLegs = moMBet.legs.map((leg) => {
                      switch (leg.type) {
                        case 'SingleSports': {
                          if (
                            closedPropositionIds.includes(
                              leg.legs.propositionId
                            )
                          ) {
                            return {
                              ...leg,
                              isClosed: true,
                            };
                          }
                          return leg;
                        }
                        case 'SingleRacing': {
                          if (
                            closedPropositionIds.includes(
                              leg.legs.propositionId
                            )
                          ) {
                            return {
                              ...leg,
                              isClosed: true,
                            };
                          }
                          return leg;
                        }
                        case 'Blended': {
                          if (
                            leg.legs.some((l) =>
                              closedPropositionIds.includes(l.propositionId)
                            )
                          ) {
                            return {
                              ...leg,
                              isClosed: true,
                            };
                          }
                          return leg;
                        }
                        case 'SGMulti': {
                          if (
                            leg.legs.some((l) =>
                              closedPropositionIds.includes(l.propositionId)
                            )
                          ) {
                            return {
                              ...leg,
                              isClosed: true,
                            };
                          }
                          return leg;
                        }
                        case 'SRMulti': {
                          const isCLosed = isPropositionClosedForSRMulti(
                            moMBet as TMultiMoMBet,
                            closedPropositionIds
                          );

                          if (isCLosed) {
                            return {
                              ...leg,
                              isClosed: true,
                            };
                          }
                          return leg;
                        }
                        default: {
                          return leg;
                        }
                      }
                    });
                    updateMoMBet({
                      legs: updatedMomBetLegs,
                      betRejectionReason:
                        EBetSubmissionRejectionReason.PropositionClosed,
                      isClosed: true,
                    });
                    break;
                  }
                  default: {
                    updateMoMBet({
                      betRejectionReason: confirmation.rejection_reason,
                    });
                  }
                }
                break;
              }
              default: {
                updateMoMBet({
                  betSubmissionStatus:
                    EBetSubmissionConfirmationStatus.Rejected,
                });
                break;
              }
            }
          } else if (confirmation.status) {
            const { status } = confirmation;
            switch (status) {
              case EBetSubmissionConfirmationStatus.Pending:
              case EBetSubmissionConfirmationStatus.Placed: {
                const changedOdds = confirmation.changed_odds?.find(
                  (co) => co.bet_uid === moMBet.requestId
                );
                let oddsIncreased = false;
                if (changedOdds) {
                  oddsIncreased = changedOdds.new_odds > moMBet.odds;
                }
                updateMoMBet({
                  betOddsChange: null,
                  betSubmissionStatus: status,
                  betRejectionReason: null,
                  oddsIncreased,
                  odds: changedOdds?.new_odds ?? moMBet.odds,
                });
                break;
              }
              case EBetSubmissionConfirmationStatus.Rejected: {
                updateMoMBet({ betSubmissionStatus: status });
                break;
              }
              /**
               * For a reduced stake, MBL only applies to punter categories SHARP and SINGLES
               * Multi bets are not eligible for those punter categories we shouldn't expect a
               * rejection reason to be ReducedStake.
               */
              case EBetSubmissionConfirmationStatus.ReducedStake: {
                const { reduced_stake: reducedStake } = confirmation;

                const newStake =
                  Number(moMBet.stake) - (reducedStake as number) / 100;
                const reducedStakeInfo = {
                  approvedStake: newStake.toFixed(2),
                  rejectedStake: ((reducedStake as number) / 100).toFixed(2),
                };

                updateMoMBet({
                  stake: newStake.toFixed(2),
                  reducedStakeInfo,
                });
                break;
              }
              default: {
                throw new Error(`Confirmation status unsupported: ${status}`);
              }
            }
          }
        }
      }

      // If any bets are pending approval, place relevant data in local storage to allow for persistence on page refresh/revisit

      storePendingBetsLocally(
        updatedBetsWConfirmation.filter(
          (bet) =>
            bet.confirmation?.status ===
            EBetSubmissionConfirmationStatus.Pending
        )
      );

      // New bets being updated with confirmations
      _updateBet((state) => {
        const _bets = { ...(state ?? {}) } as Record<string, any>;
        const keys = Object.keys(_bets);

        keys.forEach((k) => {
          const bet = _bets[k];
          const index = updatedBetsWConfirmation.findIndex(
            (b) => b.request_id === bet?.requestId
          );

          if (index !== -1) {
            const { confirmation, legs, potential_returns, odds, stake } =
              updatedBetsWConfirmation[index] as any;
            _bets[k].confirmation = confirmation as any;
            _bets[k].potential_returns = potential_returns as any;
            _bets[k].stake = stake as any;

            if (legs) {
              _bets[k].legs = legs as any;
            }
            if (odds) {
              _bets[k].odds = odds as any;
            }
          }
        });

        return _bets;
      });

      // Update the store with the bets
      thunkAPI.dispatch(updateBetsWithConfirmations(updatedBetsWConfirmation));

      if (
        !confirmations.some(
          (con) =>
            con.rejection_reason === EBetSubmissionRejectionReason.OddsChange ||
            con.rejection_reason ===
              EBetSubmissionRejectionReason.InsufficentFund ||
            (bets.find((b) => b.request_id === con.request_id)?.type ===
              EBetSlipBetSubmissionType.Multi &&
              con.rejection_reason ===
                EBetSubmissionRejectionReason.PropositionClosed)
        )
      ) {
        thunkAPI.dispatch(clearNonStakedBets());

        _updateBet((state) => {
          const _bets = { ...(state ?? {}) };
          const keys = Object.keys(_bets);

          keys.forEach((k) => {
            const bet = _bets[k];

            if (Number(bet.stake ?? 0) <= 0) {
              delete _bets[k];
            }
          });

          return _bets;
        });
      }

      if (
        confirmations.some(
          (con) =>
            con.rejection_reason ===
            EBetSubmissionRejectionReason.InsufficentFund
        )
      ) {
        thunkAPI.dispatch(setHasSufficientFunds(false));
      }

      // Update account balance
      await thunkAPI.dispatch(getPunterAccountOverview());

      // check if any bets have a bonus_chance amount ready to go.
      const bonusChance = confirmations.find((_) => !!_.gameplay);

      if (bonusChance?.gameplay) {
        if (IS_MOBILE_APP) {
          // The mobile app shows the game play natively in RN rather than in the webview
          postMobileAppMessage('gameplay', { gameplay: bonusChance.gameplay });
        } else {
          thunkAPI.dispatch(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setBonusChanceModal({
              ...bonusChance.gameplay,
              isVisible: !hasLD,
              isLDVisible: hasLD,
            } as any)
          );
          await Promise.all([
            queryClient.invalidateQueries([apiKeys.punterAccountMyBets]),
            queryClient.invalidateQueries([apiKeys.betsForRace]),
          ]).catch(() => undefined);
        }
      } else {
        thunkAPI.dispatch(
          setBonusChanceModal(hasLD ? { isLDVisible: true } : null)
        );

        await Promise.all([
          queryClient.invalidateQueries([apiKeys.punterAccountOverview]),
          queryClient.invalidateQueries([apiKeys.punterAccountMyBets]),
          queryClient.invalidateQueries([apiKeys.betsForRace]),
        ]).catch(() => undefined);
      }
      // TODO: Confirm this
      // Timeout set below to clear the bet slip after X seconds so the user
      // has some time to see the successful submission state of their bet slip
      // before clearing and starting fresh
      // This timeout can be removed if we don't care to show successful submission
      // via the bet slip UI itself
    } catch (e) {
      console.error('Encountered Betslip Submission Error', e);
      thunkAPI.dispatch(setGeneralError(true));
    }
  }
);

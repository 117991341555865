import { CSSObject } from '@chakra-ui/react';

export const SelectionPopupContainer: CSSObject = {
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  borderRadius: 'md',
  mt: '2',
  mx: ['0', null, '4'],
  zIndex: '2',
};

export const InfoText: CSSObject = {
  pl: '2',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'semibold',
};

export const InfoHeader: CSSObject = {
  alignItems: 'center',
  bg: 'blackAlpha.300',
};

export const FlexWrapper: CSSObject = {
  bgGradient: 'linear(to-b, rgba(194, 223, 255, .1), rgba(194, 223, 255, 0))',
  p: '2',
  borderTop: '1px solid',
  borderTopColor: 'whiteAlpha.300',
  borderBottomRadius: 'md',
  pos: 'relative',
  _before: {
    bgGradient: 'linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, .2))',
    bottom: 'calc(100% + 1px)',
    content: '""',
    h: '17px',
    left: '0',
    pointerEvents: 'none',
    pos: 'absolute',
    right: '0',
    transitionDuration: 'normal',
    transitionProperty: 'common',
  },
};

export const FlexInputWrapper: CSSObject = {
  color: 'white',
  fontSize: 'regular',
  '.chakra-input__group': {
    borderTop: '2px solid',
    borderBottom: '2px solid',
    borderRight: '2px solid',
    borderTopColor: 'beta.600',
    borderBottomColor: 'beta.600',
    borderRightColor: 'beta.600',
    borderRadius: 'md',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.25)',
  },
  '.chakra-input__left-addon': {
    borderLeftRadius: 'base',
    fontSize: 'sm',
    fontWeight: 'bold',
    bg: 'beta.600',
    color: 'white',
    h: 'auto',
  },
  '.chakra-input': {
    _placeholder: {
      color: 'white',
    },
    _focus: {
      border: 'none',
      bg: 'beta.200',
    },
    borderRightRadius: 'xs',
    color: 'white',
    bg: 'beta.800',
    fontSize: 'xs',
    fontWeight: 'bold',
    ':hover, :focus': {
      color: 'blackAlpha.700',
    },
  },
  '.chakra-form__label': {
    mb: '1',
  },
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  borderColor: 'blackAlpha.300',
  pos: 'relative',
  _notFirst: {
    borderTopStyle: 'dotted',
    borderTopWidth: '1px',
  },
  _before: {
    border: 'inherit',
    borderColor: 'whiteAlpha.300',
    content: '""',
    h: '1px',
    left: '0',
    pos: 'absolute',
    right: '0',
    top: '0',
  },
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    }
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-1',
  boxSize: '6',
  color: 'white',
};
import { CompetitionBySportV2Schema } from '@/views/sports/CompetitionsBySport/styles/CompetitionBySportV2.styles';

export const competitionBySportV2Styles: CompetitionBySportV2Schema = {
  containerNav: { mb: '3' },
  scrollButtonGroup: {
    mb: '3',
    variant: 'filter',
    bg: 'none',
    boxShadow: 'none',
    pos: 'relative',
  },
  scrollButtonGroupDivider: {
    mb: '3',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'gamma.800',
  },
  competitionListBoxWrapper: {
    w: 'full',
  },
  scrollButtonGroupButtonCompetition: {
    borderRadius: 'base',
    color: 'white',
    fontSize: '2xs',
    fontWeight: 'extrabold',
    h: '7',
    minW: 'auto',
    p: '2',
    textTransform: 'uppercase',
    border: '2px',
    borderColor: 'alpha.400',
    bg: 'transparent',
    _hover: {
      color: 'alpha.400',
    },
    _active: {
      bgGradient: 'linear(to-b, alpha.100, alpha.500)',
      color: 'alpha.900',
      border: 'none',
    },
    sx: {
      '&[data-active]': {
        _after: {
          display: 'none',
        },
      },
    },
  },
  scrollButtonGroupButtonAllLeagues: {
    borderRadius: 'base',
    color: 'white',
    fontSize: '2xs',
    fontWeight: 'extrabold',
    h: '7',
    minW: 'auto',
    p: '2',
    textTransform: 'uppercase',
    border: '2px',
    borderColor: 'alpha.400',
    bg: 'transparent',
    _hover: {
      color: 'alpha.400',
    },
    _active: {
      bgGradient: 'linear(to-b, alpha.100, alpha.500)',
      color: 'alpha.900',
      border: 'none',
    },
    sx: {
      '&[data-active]': {
        _after: {
          display: 'none',
        },
      },
    },
  },
  hasNextPageButtonOverride: {
    sx: {
      svg: {
        color: 'black',
        bg: 'beta.100',
        borderRadius: 'sm',
        p: '0.5',
      },
    },
  },
};

import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const FlexRaceAvailableWrapper: CSSObject = {
  color: 'white',
};

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'tab',
    fontSize: '2xs',
    h: '24px',
    sx: {
      '&[data-active="false"]': {
        px: '2',
      },
      '&[data-active="true"]': {
        borderColor: 'blackAlpha.600',
        bg: 'blackAlpha.600',
        boxShadow: '0px -1px 0px 0px rgba(255, 255, 255, 0.30) inset',
        color: 'alpha.100',
      },
    },
  },
};
import { ButtonProps, CSSObject, theme } from '@chakra-ui/react';
import { colorScheme } from '@/helpers/colorScheme';
import activeBg from '@/assets/vikingbet/images/backgrounds/btn-active-bg.png';

const active: CSSObject = {
  bgImg: `url(${activeBg})`,
  color: 'delta.300',
  bgSize: '100% 100%',
  border: 'none',
  boxShadow: '0px 0px 0px 3px rgba(0, 0, 0, 0.45) inset',

  _hover: {
    bgImg: `url(${activeBg})`,
    color: 'delta.500',
    bgSize: '100% 100%',
    boxShadow: '0px 0px 0px 4px rgba(0, 0, 0, 0.45) inset',
  },
};

const primary = {
  transition: 'all .3s linear',
  lineHeight: '1',
  fontFamily: 'SkranjiBold',
  textTransform: 'uppercase',
  bgGradient: 'linear(180deg, delta.400, delta.600)',
  color: 'gamma.700',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  border: 'var(--bc-colors-delta-700) 2px solid',
  textShadow: '0px 1px 2px rgba(255, 255, 255, 0.40)',
  height: ['9', null, '10'],
  borderRadius: 'md',
  boxSizing: 'border-box',
  fontSize: 'sm',
  w: 'auto',
  maxH: '36px',

  _hover: {
    bgGradient: 'linear(to-b, delta.600, delta.800)',
    boxShadow: 'inset 0px 0px 0px 1px var(--bc-colors-delta-600)',
  },

  _focus: {
    boxShadow: '0 2px 5px rgba(0, 0, 0, .15)',
  },

  '&:active, &[data-active="true"]': {
    bg: 'gamma.600',
    ...active,
  },

  _disabled: {
    pointerEvents: 'none',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
      boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
      color: 'beta.800',
      fontFamily: 'SkranjiBold',
      fontSize: 'sm',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),

      fontFamily: 'SkranjiBold',
      fontSize: ['sm', null, 'md'],
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      borderRadius: 'lg',
      border: '2px',
      borderColor: 'beta.700',
      bgGradient: 'linear(to-b, #FCD236, #E79004)',
      boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
      height: ['9', null, '12'],
      color: 'beta.800',
      '&:active': {
        bgGradient: 'linear(180deg, #8C742F, #896B28)',
        boxShadow: '0px 0px 0px 2px #8B7746 inset',
        border: '2px',
        borderColor: '#775826',
      },
      _hover: { bg: 'beta.500' },

      ...(props.colorScheme && colorScheme(props)),
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderColor: 'white',
      border: '2px',
      color: 'white',
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: 'full',
      textTransform: 'uppercase',

      '&:active, &:hover': {
        bg: 'white',
        color: 'beta.500',
      },
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',

      '&&': {
        '&:disabled': {
          bg: 'beta.500',
          color: 'white',
        },
      },
    }),
    ghost: {
      ...primary,
      bg: undefined,
      boxShadow: undefined,
      textShadow: undefined,

      _hover: {
        bg: 'alpha.50',
      },

      _focus: undefined,

      _active: {
        bg: 'alpha.100',
      },
    },
    filter: {
      border: '2px',
      borderColor: 'alpha.600',
      borderRadius: 'base',
      color: 'alpha.600',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',

      _hover: {
        color: 'alpha.800',
      },

      _active: {
        bgGradient: 'linear(to-b, alpha.500, alpha.600)',
        color: 'white',
        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
        border: '0px',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',

      _hover: {
        color: 'delta.800',
      },

      _active: {
        bg: 'delta.800',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'white',
      },
    },
    tab: {
      color: 'alpha.700',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      fontWeight: 'black',
      minW: 'auto',
      fontSize: '2xs',
      textTransform: 'uppercase',
      borderRadius: '3px',
      borderStyle: 'solid',
      px: '2',
      py: '1',
      h: '6',
      bg: 'zeta.500',
      transition: 'all .2s ease-in-out',
      boxShadow:
        '0px 1px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
      '&:hover': {
        color: 'blackAlpha.700',
        bg: 'zeta.100',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
        fontWeight: 'black',
      },

      '&:active, &[data-active="true"]': {
        color: 'white',
        textShadow: undefined,
        fontWeight: 'bold',
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
        '&:hover': {
          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
        },
      },
    },
    quaddiesTab: {
      boxShadow: 'none',
      fontSize: 'xs',
      textShadow: 'none',
      fontWeight: 'bold',
      borderRadius: 'none',
      fontFamily: 'body',
      h: '8',
      textTransform: 'uppercase',
      color: 'white',
      borderBottom: '3px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '1',
      ':active, &[data-active="true"]': {
        color: 'alpha.600',
        fontWeight: 'normal',
        borderBottom: '3px solid',
        borderBottomColor: 'alpha.600',
      },
    },
    raceNumbers: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...primary,
      fontFamily: 'body',
      bgGradient: 'linear(to-b, beta.500, beta.600)',
      border: 'none',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      _hover: {
        bgGradient: 'linear(to-b, beta.400, beta.500)',
      },
    },
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};

import { ButtonProps, theme } from '@chakra-ui/react';
import CloseButton from '@/assets/templebet/images/close-filter.svg';

const primary = {
  color: 'beta.500',
  fontSize: 'sm',
  fontWeight: 'normal',
  py: '2',
  px: '4',
  bgImage:
    'linear-gradient(to bottom, var(--bc-colors-beta-700), var(--bc-colors-beta-800))',
  textShadow: '0px 4px 4px var(--bc-colors-blackAlpha-400)',
  fontFamily: 'accent',
  borderRadius: 'md',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset, 0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  _hover: {
    bgImage:
      'linear-gradient(to bottom, var(--bc-colors-delta-400), var(--bc-colors-delta-500))',
    color: 'white',
  },
  _active: {
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
    bgImage:
      'linear-gradient(180deg, var(--bc-colors-beta-900) 0%, var(--bc-colors-beta-900) 100%)',
  },
  _focus: {
    bgImage:
      'linear-gradient(to bottom, var(--bc-colors-beta-900), var(--bc-colors-beta-900))',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
  _disabled: {
    bgImage:
      'linear-gradient(to bottom, var(--bc-colors-gray-400), var(--bc-colors-gray-400))',
    borderColor: 'gray.600',
    color: 'gray.200',
    textShadow: '0px 1px 4px var(--bc-colors-blackAlpha-300)',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
    opacity: '0.4',
  },
};

const secondary = {
  fontFamily: 'accent',
  color: 'beta.500',
  fontWeight: 'normal',
  bgGradient:
    'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  boxShadow:
    '0px 4px 6px 0px var(--bc-colors-blackAlpha-500),0px 0px 0px 2px var(--bc-colors-beta-400) inset,0px 0px 0px 4px #20273f inset,0px 0px 0px 5px var(--bc-colors-whiteAlpha-400) inset',
  p: '3',
  _hover: {
    color: 'beta.500',
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-alpha-800) 0%, var(--bc-colors-alpha-800) 100%)',
    boxShadow:
      '0px 0px 0px 2px var(--bc-colors-beta-400) inset,0px 0px 0px 3.5px var(--bc-colors-alpha-700) inset',
  },
  _active: {
    boxShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-400)',
    backgroundImage:
      'linear-gradient(180deg, var(--bc-colors-alpha-900) 0%, var(--bc-colors-alpha-900) 100%)',
  },
  _focus: {
    boxShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-400)',
    backgroundImage:
      'linear-gradient(180deg, var(--bc-colors-alpha-900) 0%, var(--bc-colors-alpha-900) 100%)',
  },
};

const outline = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'beta.800',
  color: 'beta.800',
  fontFamily: 'accent',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  _hover: {
    borderColor: 'beta.500',
    color: 'beta.500',
  },
  _active: {
    boxShadow: ' 0px 0px 4px 4px var(--bc-colors-blackAlpha-400) inset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      opacity: 0.4,
      borderColor: 'beta.800',
      color: 'beta.800',
    },
  },
};

const quickAccess = {
  bgImage:
    'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  boxShadow:
    '0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 4px #20273f inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-400) inset',
  borderRadius: 'lg',
  color: 'beta.500',
  fontFamily: 'accent',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 4px 4px var(--bc-colors-blackAlpha-400)',
  _hover: {
    bgImage: 'none',
    color: 'white',
    bg: 'linear-gradient(to bottom, var(--bc-colors-delta-600), var(--bc-colors-delta-600))',
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300),0px 0px 0px 3px var(--bc-colors-blackAlpha-500) inset,0px 0px 0px 1px var(--bc-colors-beta-900) inset',
  },
  ':active, &[data-active="true"]': {
    color: 'beta.300',
    bgImage: 'none',
    bgGradient:
      'linear(to-b, var(--bc-colors-alpha-500) 0%,var(--bc-colors-alpha-600) 100%)',
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset, 0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  },
  _disabled: {
    opacity: 0.4,
    boxShadow:
      '0px 0px 0px 1px var(--bc-colors-alpha-700) inset, 0px 0px 0px 2px var(--bc-colors-blackAlpha-500) inset, 0px 4px 4px 0px var(--bc-colors-blackAlpha-400)',
    '&&:hover': {
      bgGradient:
        'linear(to-b,var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
      boxShadow:
        '0px 0px 0px 1px var(--bc-colors-alpha-700) inset, 0px 0px 0px 2px var(--bc-colors-blackAlpha-500) inset, 0px 4px 4px 0px var(--bc-colors-blackAlpha-400)',
      color: 'beta.500',
      opacity: 0.4,
    },
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const odds = {
  minW: '8',
  background:
    'linear-gradient(180deg,  var(--bc-colors-beta-700) 0%,  var(--bc-colors-beta-800) 100%)',
  borderRadius: 'md',
  color: 'beta.500',
  fontFamily: 'body',
  fontSize: 'sm',
  fontWeight: 'black',
  textTransform: 'capitalize',
  textShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-500)',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset, 0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  transition: 'all 0.3s',
  _hover: {
    color: 'white',
    bg: 'linear-gradient(180deg, var(--bc-colors-delta-400) 0%, var(--bc-colors-delta-500) 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'linear-gradient(180deg, var(--bc-colors-beta-900) 0%, var(--bc-colors-beta-900) 100%)',
    boxShadow:
      '0px 2px 4px 0px var(--bc-colors-accent-50),0px 1px 0px 0px var(--bc-colors-whiteAlpha-500)',
    color: 'beta.500',
    textShadow: 'unset',
  },
  _focus: {
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset, 0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  },
  _disabled: {
    opacity: '1',
    bg: 'linear-gradient(180deg, var(--bc-colors-gray-400) 0%, var(--bc-colors-gray-400) 100%)',
    color: 'gray.200',
    textShadow: '0px 2px 4px var(--bc-colors-blackAlpha-300)',
    boxShadow:
      '0px 0px 4px 0px var(--bc-colors-blackAlpha-400) inset, 0px 1px 0px 0px var(--bc-colors-whiteAlpha-300)',
    pointerEvents: 'none',
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const tab = {
  color: 'white',
  bg: 'beta.800',
  fontWeight: 'normal',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'uppercase',
  borderRadius: '0',
  px: '2',
  pb: '1',
  mb: '0',
  minW: 'fit-content',
  transition: 'all .2s ease-in-out',
  borderBottom: '3px solid',
  borderColor: 'transparent',
  '&:focus': {
    color: 'beta.500',
  },
  '&:active, &[data-active="true"]': {
    borderColor: 'beta.500',
    '&:hover, &:focus': {
      color: 'beta.500',
    },
  },
};

const filter = {
  borderRadius: 'md',
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'extrabold',
  h: '7',
  minW: 'auto',
  p: '2',
  textTransform: 'uppercase',
  border: '2px',
  borderColor: 'beta.500',
  bg: 'transparent',
  ml: '0',
  _hover: {
    color: 'beta.500',
    fontWeight: 'extrabold',
  },
  _active: {
    bg: 'beta.500',
    color: 'beta.800',
    fontWeight: 'extrabold',
    '&:after': {
      border: 'none',
      content: '""',
      position: 'absolute',
      display: 'block',
      boxSize: '14px',
      top: '7px',
      right: '1.5',
      borderRadius: 'full',
      pr: '2',
      backgroundImage: `url(${CloseButton})`,
      backgroundSize: '2.5',
      backgroundRepeat: 'no-repeat',
    },
  },
};

const segment = {
  borderRadius: 'base',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: '5',
  px: '3',
  py: '3px',
  textTransform: 'uppercase',
  bg: 'transparent',
  _hover: {
    color: 'alpha.400',
  },
  _active: {
    bg: 'alpha.400',
    color: 'alpha.700',
  },
};

const unstyled = {
  fontSize: 'sm',
  textShadow: 'none',
  color: 'beta.300',
  h: 'fit-content',
  textTransform: 'uppercase',
  sx: {
    '&&': {
      fontWeight: 'bold',
    },
    svg: {
      bg: 'beta.500',
      p: '0.5',
      color: 'blackAlpha.500',
      border: '1px solid red',
      borderColor: 'beta.500',
      borderRadius: 'lg',
      _hover: {
        bg: 'alpha.300',
      },
      _active: {
        bg: 'alpha.300',
      },
    },
  },
  _hover: {
    color: 'alpha.300',
  },
  _active: {
    color: 'alpha.300',
  },
};

const quaddiesTab = {
  boxShadow: 'none',
  fontSize: 'sm',
  textShadow: 'none',
  fontWeight: 'normal',
  borderRadius: 'none',
  fontFamily: 'accent',
  h: '8',
  textTransform: 'uppercase',
  color: 'white',
  borderBottom: '3px solid',
  borderBottomColor: 'transparent',
  px: '3',
  pt: '1',
  ':active, &[data-active="true"]': {
    color: 'delta.400',
    fontWeight: 'normal',
    borderBottom: '3px solid',
    borderBottomColor: 'delta.400',
    _hover: {
      '&&': {
        border: 'none',
      },
    },
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    quickAccess: () => ({
      ...quickAccess,
    }),
    odds: () => ({
      ...odds,
    }),
    tab: () => ({
      ...tab,
    }),
    filter: () => ({
      ...filter,
    }),
    segment: () => ({
      ...segment,
    }),
    quaddiesTab: () => ({
      ...quaddiesTab,
    }),
    unstyled: () => ({
      ...unstyled,
    }),
  },
  defaultProps: { colorScheme: null },
};

import { TextProps, CSSObject } from '@chakra-ui/react';

export const FormControlGroup: CSSObject = {
  'input#email': {
    border: 0,
  },
  '.chakra-form__error-message': {
    bg: 'gray.900',
    color: 'gray.200',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset',
  },
  'div.chakra-input__group': {
    borderRadius: 'md',
    p: 0.5,
    bg: 'rgba(255, 255, 255, 0.001)',
    boxShadow:
      '0px 0px 0px 1px rgba(187, 135, 37, 1) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.3)',
  },
  'div.chakra-input__left-element': {
    h: '42px',
    width: 9,
  },
  'div.chakra-input__right-element': {
    h: '42px',
    width: 9,
  },
};

export const formLabelError: TextProps = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'medium',
  bg: 'red.600',
  p: '1',
  borderRadius: 'base',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
  mt: '1.5',
  sx: { svg: { top: 0 } },
};

export const FormLabelThemed: CSSObject = {
  color: 'gamma.200',
  fontSize: 'xs',
  fontWeight: 'semibold',
  mt: '2',
};

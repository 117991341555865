import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'primary',
  height: '38px',
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, '2.5'],
  bgGradient: 'linear(to-b, rgba(194, 223, 255, .1), rgba(194, 223, 255, 0))',
  borderColor: 'whiteAlpha.300',
  rowGap: ['6', null, '2'],
  _before: {
    bgGradient: 'linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, .2))',
    bottom: 'calc(100% + 1px)',
    content: '""',
    h: '17px',
    left: '0',
    pointerEvents: 'none',
    pos: 'absolute',
    right: '0',
    transitionDuration: 'normal',
    transitionProperty: 'common',
  },
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'bold',
  },

  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'beta.600',
    border: '2px',
    borderLeft: 'none',
    borderRightColor: 'alpha.500',
    borderTopColor: 'alpha.400',
    borderBottomColor: 'alpha.700',
    color: 'white',
    fontWeight: 'bold',
    borderRightRadius: 'md',

    _placeholder: {
      color: 'white',
      opacity: '0.5',
    },

    _hover: {
      bg: 'blackAlpha.200',
      borderRightColor: 'alpha.500',
      borderTopColor: 'alpha.400',
      borderBottomColor: 'alpha.700',
    },

    _focus: {
      borderRightColor: 'alpha.500',
      borderTopColor: 'alpha.400',
      borderBottomColor: 'alpha.700',
      boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.3)',
      bg: 'beta.400',

      _placeholder: {
        color: 'white',
        opacity: '0.5',
      },
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    fontWeight: 'extrabold',
    bgGradient: 'linear(to-b, alpha.400, alpha.700)',
    borderLeftRadius: 'md',
  },
};

export const SelectionPopupContainer: CSSObject = {
  my: [null, null, '4'],
  mx: [null, null, '4'],
  mt: '4',
  bgGradient: 'linear(to-b, beta.300, beta.200)',
  borderRadius: 'base',
  zIndex: '10',
};

export const InfoHeader: CSSObject = {
  bg: 'blackAlpha.300',
  h: '7',
  px: '2',
  py: '1.5',
  borderBottom: '1px solid',
  borderColor: 'whiteAlpha.300',
  alignItems: 'center',
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontWeight: 'black',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  borderColor: 'blackAlpha.300',
  borderTopStyle: 'dotted',
  borderTopWidth: '1px',
  _first: {
    border: 'none',
  },
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    }
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-1',
  boxSize: '6',
  color: 'white',
};
import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    sx: {},
  },
  iconButtonScrollRight: {
    bgGradient: 'linear(to-b, gamma.300, gamma.400)',
    color: 'alpha.700',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    border: 'none',
    h: 9,
    w: 4,
    mt: '0',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    sx: {
      '&:active': {
        _after: {
          display: 'none',
        },
      },
    },
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    sx: {},
  },
  iconButtonScrollLeft: {
    bgGradient: 'linear(to-b, gamma.300, gamma.400)',
    border: 'none',
    color: 'alpha.700',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    h: 9,
    w: 4,
    mt: '0',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    sx: {
      '&:active': {
        _after: {
          display: 'none',
        },
      },
    },
  },
  flexWrapper: {
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
  },
  buttonGroupSports: {
    bg: 'alpha.700',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    borderRadius: 'base',
    display: 'inline-flex',
    flex: '1',
    overflowX: 'auto',
    pl: '1',
    pt: '0.5',
    pb: ['1', null, null, '0'],
    spacing: '1',
    whiteSpace: 'nowrap',
    sx: { '::-webkit-scrollbar': { display: 'none' } },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    gap: 1,
    isAttached: true,
    variant: 'filter',
  },
};

import { SpecialsStylesSchema } from './Specials.styles';

export const specialsStyles: SpecialsStylesSchema = {
  heading: {
    color: 'gamma.200',
    fontSize: 'sm',
    pl: 1,
  },
  boxWrapper: {
    pt: 0,
  },
};

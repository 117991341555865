import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  border: 'none',
  fontFamily: 'body',
  borderColor: 'transparent',
  bg: 'alpha.200',
  overflow: 'hidden',
  borderRadius: 'md',
  '.chakra-accordion__item': {
    mb: '2',
    boxShadow:
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: 'md',
  },
  '.chakra-accordion__panel': {
    color: 'gamma.200',
    borderTop: '2px solid',
    borderTopColor: 'whiteAlpha.200',
    pt: '3',
    '.chakra-text': {
      ':not(:last-child)': {
        color: 'gamma.200',
      },
      _notFirst: {
        borderRadius: 'base',
        opacity: '0.64',
        bg: 'blackAlpha.700',
        border: 'none',
        textTransform: 'none',
        color: 'rgba(255, 255, 191, 1)',
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
    },
  },
};
export const AccordionHeader: CSSObject = {
  color: 'gamma.200',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  fontSize: 'sm',
  fontFamily: 'Staatliches',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  borderTopRadius: 'md',
  mx: '1',
  '.chakra-accordion__button': {
    px: '1',
  },
  '.chakra-accordion__icon': {
    boxSize: '6',
    color: 'gamma.500',
  },
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
};
export const OverviewPromotionsPanel: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    borderRadius: 'xs',
  },
};
export const AccordionHeaderLogOut: CSSObject = {
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  borderRadius: 'md',
  fontSize: 'sm',
  border: 'none',
  mt: '-2',
  px: '2',
  mx: '0',
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};
export const IconPerson: CSSObject = {
  boxSize: ['5', null, '5'],
  mr: '1.5',
};
export const TextAccordion: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  mr: 'auto',
  mt: '0.5',
};
export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'gamma.500',
  color: 'alpha.800',
  borderRadius: 'base',
  boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20)',
  fontSize: 'xs',
  fontWeight: 'normal',
  gap: '1',
  p: '2',
  span: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
};
export const OverviewAccordionItem: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'gamma.200',
  },
  border: 'none',
  mb: '0',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};

import { SelectionStylesSchema } from './Selections.styles';

export const selectionStyles: SelectionStylesSchema = {
  errorWrapper: {
    mx: ['-2', 0, null, '-4'],
    mb: ['0', null, null, '-6'],
    bg: 'beta.200',
    zIndex: 'dropdown',
    position: 'sticky',
    flexDir: 'column',
    alignItems: 'center',
    bottom: ['12px', '14px'],
    left: ['0', null, 'unset'],
    w: 'unset',
  },
};

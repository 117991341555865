/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Text, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { TTemplate } from '@/helpers/createTemplate';
import {
  WithdrawalCardContent,
  WithdrawalCardDetailRequested,
  WithdrawalFlexContainer,
  WithdrawalStatus,
  WithdrawalWrapper,
  ButtonWithdrawalCardCancel,
} from '../styles/RequestedWithdrawalCard.styles';
import { centsToDollars } from '@/helpers/utils';
import { EWithdrawalStatus, TWithdrawal } from '../../../../../lib/DBModels';

/**
 * FIXME: This process for creating templates is outdated. We want to move any logic into reusable components.
 */
const template = (
  withdrawalRequest: TWithdrawal,
  handleCancelWithdrawal: (id: string | undefined) => void
): TTemplate => ({
  wrapper: <WithdrawalWrapper flexDir="row" />,
  content: (
    <WithdrawalCardContent>
      <WithdrawalCardDetailRequested flexDir={['column', null, 'row']} alignItems={['start', null, 'center']} >
        <Flex>
          <Text fontWeight="semibold" fontSize="sm">
            {withdrawalRequest?.bank_nickname} :
          </Text>
          <Text
            fontWeight="semibold"
            fontSize="sm"
            color="gamma.500"
            marginLeft="1"
          >
            {`-${centsToDollars(withdrawalRequest?.amount_requested)}`}
          </Text>
        </Flex>
        <Flex fontSize="xs" fontWeight="semibold">
          <Text pt="px">
            {dayjs(withdrawalRequest?.date_requested).format('DD/MM/YYYY')}
          </Text>
          <Text px="2" color="blackAlpha.400">
            {' '}
            |{' '}
          </Text>
          <Text pt="px">
            {dayjs(withdrawalRequest?.date_requested).format('hh:mm A')}
          </Text>
          <Text px="2" color="blackAlpha.400">
            {' '}
            |{' '}
          </Text>
          <WithdrawalStatus
            status={withdrawalRequest?.withdrawal_status ?? undefined}
          >
            <FormattedMessage
              id={`account.withdraw.requests.status.${withdrawalRequest?.withdrawal_status?.toLowerCase()}`}
            />
          </WithdrawalStatus>
        </Flex>
      </WithdrawalCardDetailRequested>
    </WithdrawalCardContent>
  ),
  cancelButton: (
    <WithdrawalFlexContainer>
      {withdrawalRequest?.withdrawal_status === EWithdrawalStatus.Pending &&
        !withdrawalRequest.withdrawal_cancel_disabled && (
          <ButtonWithdrawalCardCancel
            px="5"
            py="2"
            fontSize="sm"
            w="full"
            onClick={() =>
              handleCancelWithdrawal(withdrawalRequest?.withdrawal_id)
            }
          >
            <FormattedMessage id="account.withdraw.requests.cancelbutton" />
          </ButtonWithdrawalCardCancel>
        )}
    </WithdrawalFlexContainer>
  ),
});
export default template;

import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    color: 'beta.900',
    sx: {
      filter: 'drop-shadow(0px 1px 0 rgba(255, 255, 255, 0.2))',
    },
  },
  iconButtonScrollRight: {
    variant: 'unstyled',
    bgGradient: 'linear(to-b, #DB7E2F, #A5540E)',
    color: 'beta.800',
    border: 'none',
    borderRadius: 'sm',
    borderLeftRadius: 'none',
    w: 'auto',
    minW: '4',
    _hover: {
      bgGradient: 'linear(to-b, #D57E34, #C87935)',
    },
    sx: {
      '&&': {
        h: '8',
      },
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
    },
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    color: 'beta.900',
    sx: {
      filter: 'drop-shadow(0px 1px 0 rgba(255, 255, 255, 0.2))',
    },
  },
  iconButtonScrollLeft: {
    variant: 'unstyled',
    bgGradient: 'linear(to-b, #DB7E2F, #A5540E)',
    color: 'beta.800',
    border: 'none',
    borderRadius: 'sm',
    borderRightRadius: 'none',

    w: 'auto',
    minW: '4',
    _hover: {
      bgGradient: 'linear(to-b, #D57E34, #C87935)',
    },
    sx: {
      '&&': {
        h: '8',
      },
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
    },
  },
  flexWrapper: {
    bg: 'beta.400',
    borderRadius: 'md',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.35)',
  },
  buttonGroupSports: {
    display: 'inline-flex',
    flex: '1',
    overflowY: 'hidden',
    bg: 'blackAlpha.400',
    overflowX: 'auto',
    p: '1',
    mr: [null, null, 2],
    spacing: '1',
    whiteSpace: 'nowrap',
    boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    pos: ['relative', null, null, 'absolute'],
    w: ['120px', 'auto', null],
    borderRadius: [null, null, 'md'],
    inset: 0,
    right: [null, null, null, '38px'],
    sx: { '::-webkit-scrollbar': { display: 'none' } },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    p: ['0', null, null, '3px'],
    pos: 'relative',
    spacing: '1',
    ml: 'auto',
    gap: 0.5,
    bg: 'none',
    isAttached: true,
  },
  scrollIndicator: {
    w: 2,
    h: '0.5',
    bg: 'beta.400',
    pos: 'absolute',
    bottom: 'px',
    transition: 'all 0.2s ease',
  },
};

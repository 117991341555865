import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    variant: 'tab',
    boxSize: '30px',
    minWidth: '7',
    minHeight: '7',
    fontSize: 'xs',
    borderRadius: 'md',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 14,
        '.btn-span': {
          pl: 0.5,
        },
      },
    },
  },
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',
  pt: '3px',
  '&&': {
    '.quaddie': {
      bgGradient: 'linear(to-b, beta.300, beta.500)',
      color: 'beta.600',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
      border: '2px solid',
      borderColor: 'beta.300',
      boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
      _after: {
        bgImage: 'none',
        m: '0',
      },
      '&[data-active="false"]': {
        bgGradient: 'linear(to-b, beta.300, beta.500)',
      },
      '&.active': {
        border: '2px solid',
        borderColor: 'beta.300',
        color: 'beta.300',
        bg: 'beta.700',
      },
      '&:hover': {
        color: 'beta.600',
        boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.44)',
        border: '2px solid',
        borderColor: 'beta.300',
        bg: 'beta.500',
      },
    },
  },
};

export const RaceAndTimerContainer: CSSObject = {
  borderRadius: 'base',
  bg: ['transparent', 'transparent', 'blackAlpha.400'],
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
  pr: '0',
};

export const TextRaceConditions: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    bg: 'alpha.700',
    pos: 'relative',
    mx: '1.5',
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'beta.600',
  bg: 'beta.300',
};

export const RaceStatusText: CSSObject = {
  color: 'white',
  bg: 'red.500',
};

export const RaceDetailsHeading: CSSObject = {
  color: 'gray.700',
  textTransform: 'none',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'medium',
  svg: {
    color: 'gray.700',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  m: '0',
  py: '0',
};

export const RaceRunnerList: CSSObject = {
  borderRadius: 'base',
  bg: 'white',
  p: '2.5',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 2px #000 inset',
  border: '2px',
  borderColor: '#FFE6B6',
};

export const TextTitle: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'md',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const RaceName: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  mb: '0',
  pb: '2',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  columnGap: '0',
  alignItems: 'center',
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    },
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mx: '2',
  pb: '2',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  '&&': {
    px: '0',
    w: '14',
  },
  svg: {
    color: 'gray.700',
  },
};

export const RaceResultListWrapper: CSSObject = {
  gap: '2',
  mx: '0',
  mb: '2',
  flexDirection: ['column', null, 'row'],
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'gray.700',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};

export const RaceHeaderWrapper: CSSObject = {
  pb: 2,
};
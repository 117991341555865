import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'solid',
  height: '10',
};

export const FlexWrapper: CSSObject = {
  bg: 'delta.700',
  borderTop: '1px solid',
  borderColor: 'whiteAlpha.300',
  px: ['3', null, '2.5'],
  pos: 'relative',
  _before: {
    bgGradient: 'linear(to-b, rgba(0, 0, 0, 0.00), #000)',
    opacity: '0.2',
    bottom: 'calc(100% + 1px)',
    content: '""',
    h: '4',
    left: '0',
    pointerEvents: 'none',
    pos: 'absolute',
    right: '0',
    transitionDuration: 'normal',
    transitionProperty: 'common',
  },
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'bold',
  },

  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'beta.600',
    border: '2px',
    borderLeft: 'none',
    borderRightColor: 'alpha.500',
    borderTopColor: 'alpha.400',
    borderBottomColor: 'alpha.700',
    color: 'white',
    fontWeight: 'bold',
    borderRightRadius: 'md',

    _placeholder: {
      color: 'white',
      opacity: '0.5',
    },

    _hover: {
      bg: 'blackAlpha.200',
      borderRightColor: 'alpha.500',
      borderTopColor: 'alpha.400',
      borderBottomColor: 'alpha.700',
    },

    _focus: {
      borderRightColor: 'alpha.500',
      borderTopColor: 'alpha.400',
      borderBottomColor: 'alpha.700',
      boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.3)',

      _placeholder: {
        color: 'white',
        opacity: '0.5',
      },
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    fontWeight: 'extrabold',
    bgGradient: 'linear(to-b, alpha.400, alpha.700)',
    borderLeftRadius: 'md',
    w: '10',
  },
};

export const SelectionPopupContainer: CSSObject = {
  my: [null, null, '4'],
  mx: [null, null, '4'],
  mt: '4',
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  borderRadius: 'md',
  zIndex: 'docked',
};

export const InfoHeader: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  alignItems: 'center',
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const TextAddToBetslipButton: CSSObject = {
  w: '64',
  fontWeight: 'normal',
  fontFamily: 'CarterOne',
  textTransform: 'capitalize',
  fontSize: 'md',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  bg: 'delta.700',
  pos: 'relative',
  borderColor: 'blackAlpha.300',
  _first: {
    border: 'none',
  },
  _notFirst: {
    borderTopStyle: 'dotted',
    borderTopWidth: '1px',
  },
  _before: {
    border: 'inherit',
    borderColor: 'whiteAlpha.300',
    content: '""',
    h: '1px',
    left: '0',
    pos: 'absolute',
    right: '0',
    top: '0',
  },
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
  },
};
export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-1',
  boxSize: '6',
  color: 'black',
};

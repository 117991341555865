import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { keys } from '../api/api.keys';
import { queryContentful } from '../api/contentful/contentful';
import { logError } from '@/helpers/utils';
import { useFeatureFlag } from '@/store/FeatureFlagStore';

/**
 * Hook that polls contentful endpoint to ensure the bookie hasn't brought
 * down the site for maintenance. If so, navigate to maintenance route.
 */
export const useMaintenance = () => {
  const navigate = useNavigate();
  const webSplashPageEnabled = useFeatureFlag('WEB_SPLASH_PAGE_ENABLED');

  const memoedWebSplashPageEnabled = useMemo(
    () => webSplashPageEnabled,
    [webSplashPageEnabled]
  );
  const { pathname } = useLocation();
  const { data: downForMaintenance, isInitialLoading } = useQuery(
    [keys.contentful],
    queryContentful,
    {
      staleTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      onError(err) {
        logError(err);
      },
    }
  );

  useEffect(() => {
    if (
      memoedWebSplashPageEnabled &&
      window.location.pathname !== '/splash' &&
      window.location.pathname !== '/maintenance'
    ) {
      window.location.href = '/maintenance';
      return;
    }

    switch (downForMaintenance) {
      case undefined:
        return;
      case true:
        navigate('/maintenance', { replace: true });
        break;
      case false:
        if (pathname === '/maintenance') {
          if (memoedWebSplashPageEnabled) {
            return;
          }
          navigate('/', { replace: true });
        }
        break;
      default:
    }
  }, [downForMaintenance, navigate, pathname, memoedWebSplashPageEnabled]);

  return {
    downForMaintenance,
    isLoading: isInitialLoading,
  };
};

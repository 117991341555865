import { ButtonProps, theme } from '@chakra-ui/react';
import { colorScheme } from '@/helpers/colorScheme';

const button = {
  fontWeight: 'extrabold',
  fontSize: 'xs',
  borderRadius: 'base',
  textTransform: 'uppercase',
  h: '9',
};

const primary = {
  ...button,
  bg: 'beta.500',
  color: 'white',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',

  '&:hover': {
    bg: 'beta.800',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  },

  '&[data-active="true"]': {
    bg: 'beta.100',
    boxShadow: 'inset 0px 3px 4px #000000',
    color: 'beta.800',
  },

  '&:disabled': {
    opacity: '0.5',
  },
};

const secondary = {
  ...button,
  bg: 'alpha.500',
  color: 'white',

  '&:hover, &:focus': {
    bg: 'alpha.700',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  },
  '&:active': {
    bg: 'alpha.100',
    boxShadow: 'inset 0px 3px 4px #000000',
  },

  '&:disabled': {
    bg: 'blackAlpha.200',
    boxShadow: 'inset 0px 3px 4px #000000',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    tertiary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
      border: '2px',
      borderColor: 'white',
      boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.30)',

      '&[data-active="true"]': {
        bg: 'beta.100',
        border: 'none',

        borderColor: 'transparent',
        color: 'beta.800',
        textShadow: 'none',
        boxShadow: 'inset 0px 2px 4px #A97F81',

        _focus: {
          bg: 'beta.100',
          boxShadow: 'inset 0px 2px 4px #A97F81',
        },
        _hover: {
          bg: 'beta.200',
          boxShadow: 'inset 0px 2px 4px #A97F81',
        },
      },

      ...(props.colorScheme && colorScheme(props)),
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderColor: 'alpha.500',
      border: '2px',
      color: 'white',
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: 'sm',
      textTransform: 'uppercase',

      '&:active, &:hover': {
        bg: 'white',
        color: 'beta.500',
      },
    }),
    filter: {
      border: '2px',
      borderColor: 'alpha.100',
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',

      _hover: {
        color: 'alpha.100',
      },

      _active: {
        borderColor: 'white',
        color: 'white',
        bg: 'beta.500',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '5',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',

      _hover: {
        color: 'alpha.200',
      },

      _active: {
        bg: 'alpha.200',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      },
    },
    tab: {
      color: 'white',
      bgGradient: 'linear(to-b, alpha.300, alpha.700)',
      fontWeight: 'semibold',
      fontSize: '2xs',
      textTransform: 'uppercase',
      borderRadius: 'base',
      px: '2',
      py: '1',
      minW: 'fit-content',
      h: '6',
      transition: 'all .2s ease-in-out',

      boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)',
      '&:hover, &:focus': {
        color: 'white',
        bgGradient: 'linear(to-b, alpha.700 , alpha.800)',
      },

      '&:active, &[data-active="true"]': {
        color: 'gray.600',
        bgGradient: 'linear(to-b, gray.200, gray.300)',

        '&:hover, &:focus': {
          color: 'gray.700',
          bgGradient: 'linear(to-b, gray.300 , alpha.400)',
        },
      },
    },
    quaddiesTab: () => ({
      boxShadow: 'none',
      fontSize: 'xs',
      textShadow: 'none',
      fontWeight: 'bold',
      borderRadius: 'none',
      fontFamily: 'body',
      h: '8',
      textTransform: 'uppercase',
      color: 'white',
      borderBottom: '3px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '1',
      ':active, &[data-active="true"]': {
        color: 'alpha.600',
        fontWeight: 'normal',
        borderBottom: '3px solid',
        borderBottomColor: 'alpha.600',
        _hover: {
          '&&': {
            border: 'none',
          },
        },
      },
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',
    }),
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};

import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const TextRaceConditions: CSSObject = {
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    pos: 'relative',
    mx: '1.5',
    bg: 'alpha.400',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  bg: ['transparent', 'transparent', 'blackAlpha.400'],
  borderRadius: 'base',
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
  mb: ['1.5', null],
  lineHeight: 'normal',
};

export const FlexPageHeaderTitleWrapper: CSSObject = {
  mx: ['0', '0'],
};

export const RaceHeaderWrapper: CSSObject = {
  '& .state-inprogress': {
    color: 'black',
  },
  ml: ['0', '0'],
};

export const RaceName: CSSObject = {
  fontWeight: 'bold',
  fontSize: '12px',
  color: 'white',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  py: '2',
  my: '-2',
  ml: '-1.5',
};

export const RaceRunnerList: CSSObject = {
  p: '2',
  bg: 'white',
  mx: '3',
  mb: '3',
  borderRadius: '6px',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  border: '2px solid',
  borderColor: 'beta.600',
  boxSizing: 'border-box',
};

export const RaceDetailsHeading: CSSObject = {
  textTransform: 'capitalize',
  color: 'alpha.900',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    pb: '2px',
    borderRadius: 'md',
    width: '5',
    minW: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 2px',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
    fontWeight: 'black',
    fontSize: 'sm',
    fontFamily: 'Roboto',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 14,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="true"][data-closed="true"] ': {
        borderColor: 'alpha.800',
        color: 'blackAlpha.700',
        bg: 'alpha.600',
        boxShadow:
          '0px 1px 2px 0px rgba(0, 0, 0, 0.50) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
      },
      '&[data-active="true"]': {
        border: '2px solid',
        borderColor: 'alpha.800',
        bg: 'alpha.600',
        boxShadow:
          '0px 1px 2px 0px rgba(0, 0, 0, 0.50) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
      },
      '&[data-active="false"]': {
        border: '2px solid',
        borderColor: 'white',
        background: 'linear-gradient(180deg, #7EE428 0%, #4FBC03 100%)',
        boxShadow:
          '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
      },
      '&[data-closed="true"]': {
        border: '2px solid',
        borderColor: 'white',
        bg: 'gray.300',
        borderRadius: 'md',
        color: 'blackAlpha.400',
        fontWeight: 'bold',
        fontSize: 'sm',
        boxShadow:
          '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
      },
    },
  },
};

export const TextTitle: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'white',
};

export const ResultedStatusLabel: CSSObject = {
  bg: 'alpha.400',
  color: 'alpha.900',
};

export const RaceStatusText: CSSObject = {
  bg: 'orange.500',
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '2',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  color: 'black',
  fontSize: ['2xs', null, 'sm'],
  '&&': {
    w: '14',
    p: '0',
  },
};

export const RaceResultListWrapper: CSSObject = {
  mb: ['3', '0', null],
  mx: '3',
  flexDirection: 'column',
};

export const FlexHeaderMetaWrapper: CSSObject = {
  '.state-prior': {
    color: 'white',
  },
};

export const CountdownTimerContainer: CSSObject = {
  borderRadius: '3px',
};

export const ButtonGroupWrapper: CSSObject = {
  ml: ['0', '0'],
  pl: 1,
  pt: '3px',
  '&&': {
    '.quaddie': {
      border: '2px solid',
      borderColor: 'white',
      bgGradient: 'linear(to-b, beta.400, beta.500)',
      boxShadow:
        '0px 2px 6px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset',
      color: 'beta.700',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
      '&[data-active="false"]': {
        bgGradient: 'linear(to-b, beta.400, beta.500)',
      },
      '&.active': {
        boxShadow: 'none',
        border: '2px solid',
        borderColor: 'white',
        textShadow: '0px 1px 2px rgba(0, 0, 0, 0.45)',
        color: 'beta.400',
        bg: 'beta.700',
      },
      '&:hover': {
        bgGradient: 'linear(to-b, beta.600, beta.600)',
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset',
      },
    },
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mr: '2',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  alignItems: 'center',
  columnGap: '0',
  mb: [0, null, '1'],
  _even: {
    mr: [0, null, '10'],
  },
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      bgGradient: 'linear(to-b, beta.400, beta.500)',
    },
  },
  svg: {
    color: 'white',
  },
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'black',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};

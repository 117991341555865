import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    minH: 'unset',
    minW: 'unset',
    boxSize: '9',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 14,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="true"]': {
        '&&': {
          bg: 'gamma.600',
          boxShadow: 'inset 0px 3px 0px 1px rgba(0, 0, 0, 0.3)',
          color: 'white',
          border: 'none',
        },
      },

      '&[data-closed="true"]': {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'white',
        boxShadow:
          '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
        bg: 'gray.300',
        color: 'blackAlpha.400',
        textShadow: 'unset',
        px: '3',
      },
    },
  },
};

export const TextRaceConditions: CSSObject = {
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    bg: 'alpha.500',
    pos: 'relative',
    mx: '1.5',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  bg: ['transparent', 'transparent', 'rgba(0, 0, 0, 0.08)'],
  borderRadius: 'base',
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
  mb: ['1.5', null],
};

export const RaceHeaderWrapper: CSSObject = {
  mx: ['-2', null, null, null, '-1'],
  mb: '-2',
};

export const RaceName: CSSObject = {
  fontWeight: 'bold',
  fontSize: ['2xs', 'xs'],
  color: 'gamma.600',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  py: '2',
  my: '-2',
};

export const ButtonGroupWrapper: CSSObject = {
  py: '1',
  gap: '1',
  pt: '3px',
  '&&': {
    '.quaddie': {
      boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
      bg: 'alpha.800',
      color: 'white',
      textShadow: '0px 1px 3px rgba(0, 0, 0, 0.30)',
      border: '2px solid',
      borderColor: 'alpha.400',
      '&[data-active="false"]': {
        bg: 'alpha.800',
      },
      '&.active': {
        border: '2px solid',
        borderColor: 'alpha.400',
        boxShadow:
        '0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
        color: 'beta.900',
        bg: 'blackAlpha.400',
      },
      '&:hover': {
        border: '2px solid',
        borderColor: 'alpha.400',
        color: 'white',
        bg: 'alpha.600',
        boxShadow: '0px 0px 0px 2px #233B75 inset',
      },
    },
  },
};

export const RaceRunnerList: CSSObject = {
  p: '2',
  border: '1px',
  borderColor: 'beta.600',
  bg: 'white',
  borderRadius: 'lg',
};

export const RaceDetailsHeading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  color: 'black',
  fontSize: ['2xs', null, 'sm'],
  '&&': {
    w: '12',
    p: '0',
  },
};

export const TextTitle: CSSObject = {
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'md',
  color: 'gamma.600',
};

export const ResultedStatusLabel: CSSObject = {
  color: 'white',
  bg: '#38A169',
};

export const RaceStatusText: CSSObject = {
  bg: 'delta.500',
};

export const RaceRunnerListHeadings: CSSObject = {
  my: '1',
  alignItems: 'center',
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'white',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};

export const RaceResultListWrapper: CSSObject = {
  flexDirection: ['column', null, 'row'],
}
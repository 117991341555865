import React from 'react';
import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { TTemplate } from '@/helpers/createTemplate';
import Card from '../Card';
import {
  FlexFeatureCardWrapper,
  FlexFeatureTitleWrapper,
  FlexFeatureWrapper,
  ImageMascot,
  textSloganProps,
} from '../styles/Featured.styles';
import baseTemplate from './template.default';
import horse from '@/assets/sterlingparker/images/horsehead.png';

const template: TTemplate = {
  wrapper: <FlexFeatureWrapper />,
  feature: (
    <FlexFeatureTitleWrapper>
      <Text {...textSloganProps}>
        <FormattedMessage id="promo.racing.featuredRace" />
      </Text>
      <ImageMascot src={horse} />
    </FlexFeatureTitleWrapper>
  ),
  card: (
    <FlexFeatureCardWrapper>
      <Card />
    </FlexFeatureCardWrapper>
  ),

  ...(baseTemplate?.first as TTemplate),
};

export default template;

import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/templebet/images/onboarding/onboardingAside.png';
import bgMobile from '@/assets/templebet/images/onboarding/mobileBgOnboarding.png';

export const WrapperAside: CSSObject = {
  bgImage: [`url(${bgMobile})`, null, `url(${bgDesktop})`],
  backgroundColor: 'beta.800',
  position: 'relative',
  top: '0',
  bgPos: ['initial', 'center', 'top'],
  bgRepeat: ['no-repeat, repeat', null, 'no-repeat, repeat'],
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'flex-start',
  px: [null, null, '10px'],
  borderBottom: ['3px  solid', null, 'unset'],
  borderBottomColor: ['beta.600', null, 'unset'],
  h: ['50vw', null, 'full'],
  borderLeftRadius: ['unset', null, 'md'],
  '&&': {
    bgSize: '100%',
    p: 0,
    display: 'flex',
    flexDir: 'column',
    justifyContent: ['space-around', null, 'flex-start'],
  },
};

import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'tertiary',
  sx: {
    '&&': {
      h: 'unset',
    },
  },
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, 'none'],
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  pos: 'relative',
  _before: {
    bgGradient: 'linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, .2))',
    bottom: 'calc(100% + 1px)',
    content: '""',
    h: '17px',
    left: '0',
    pointerEvents: 'none',
    pos: 'absolute',
    right: '0',
    transitionDuration: 'normal',
    transitionProperty: 'common',
  },
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'bold',
  },

  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'alpha.700',
    border: '2px',
    borderColor: 'alpha.100',
    color: 'white',
    borderRightRadius: 'lg',
    fontWeight: 'semibold',

    _placeholder: {
      color: 'white',
    },

    '&:hover, &:active, &:focus, &:visited': {
      bg: 'alpha.800',
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    bg: 'alpha.100',
    borderLeftRadius: 'lg',
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '4',
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  borderRadius: 'lg',
  zIndex: 'docked',
};

export const InfoHeader: CSSObject = {
  bg: 'blackAlpha.300',
  h: '7',
  px: '2',
  py: '1.5',
  alignItems: 'center',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontWeight: 'medium',
};

export const TextAddToBetslipButton: CSSObject = {
  filter: 'drop-shadow(0px 1px 1px rgba(247, 215, 31, 0.7))',
  _active: {
    filter: 'none',
  },
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  boxShadow: '0 1px rgba(255, 255, 255, .15), 0 1px rgba(255, 255, 255, .15) inset',
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    }
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-1',
  boxSize: '6',
  color: 'white',
};
import { CSSObject } from '@chakra-ui/react';
import welcome from '../../../assets/wellbet/images/backgrounds/welcome.svg';
import welcomeMobile from '@/assets/wellbet/images/backgrounds/welcomeMobile.png';

export const TextTitle: CSSObject = {
  fontSize: ['26px', null, '3xl'],
  fontWeight: 'semibold',
  lineHeight: '30px',
  display: 'flex',
  mt: ['1.5', null, '30px'],
  flex: ['1', null, 'unset'],
  filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25))',
};

export const TextSubtitle: CSSObject = {
  fontSize: 'xs',
  flex: '1',
  mt: ['unset', null, '3'],
};

export const TextCTAInfo: CSSObject = {
  fontSize: 'sm',
  mb: ['6', null, 'unset'],
};

export const TextCTALink: CSSObject = {
  color: 'gamma.500',
  fontWeight: 'bold',
};

export const FlexAsideInfo: CSSObject = {
  gridArea: '1/1',
  flexDir: 'column',
  alignItems: ['center', null, 'unset'],
  zIndex: 'overlay',
  m: ['unset', null, '10'],
  mt: ['2', null, '10'],
  color: 'white',
};

export const GridAsideWrapper: CSSObject = {
  position: 'relative',
  h: ['336px', null, 'unset'],
};

export const BoxVideoContainer: CSSObject = {
  display: 'block',
  w: '300px',
  gridArea: '1/1',
  borderRadius: 'lg',
};

export const GlobalStyles: CSSObject = {
  '.bc-aside': {
    background: `url(${welcomeMobile})`,
    backgroundColor: '#349BB0',
    backgroundSize: 'auto 280px',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    '@media (min-width: 370px)': {
      backgroundSize: 'auto 287px',
    },
    '@media (min-width: 768px)': {
      background: `url('${welcome}'), linear-gradient(180deg, #1C74CC 0%, #1C74CC 100%)`,
      backgroundPosition: 'top, bottom',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
  },
};

import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema, TLinkRaceItem } from './RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'gray.400', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'gray.400'] },
};

export const FlexHeading: CSSObject = {
  bgGradient: 'linear(to-b, alpha.700, alpha.700)',
  color: 'white',
  borderTopRadius: 'lg',
  top: ['12', '84px'],
  mx: ['2', '0'],
  position: 'unset',
};

export const IconHeading: CSSObject = {
  boxSize: '7',
  color: 'alpha.700',
  bg: 'white',
  borderRadius: 'lg',
  p: '1.5',
};

export const BoxPageWrapper: CSSObject = {
  mx: ['-2', '0'],
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
};

export const TextRaceResults: CSSObject = {
  color: 'gray.700',
};

export const LinkRaceItem = ({ isActive, isToteMultiAvailable }: TLinkRaceItem) => ({
  bg: [
    null, 
    null, 
    (!isActive && 'gray.200') || 
    (isToteMultiAvailable && 'alpha.200') || 
    'white'
  ],
  color: isActive ? [null, null, 'black'] : [null, null, 'gray.700'],
  borderColor: 'gray.300',
});

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'row-reverse',
  columnGap: '4',
};

export const ButtonChevron: CSSObject = {
  color: 'white',
  borderRadius: 'full',
  border: '2px',
  borderColor: 'white',
  boxSize: '5',
};

export const BoxVenueName: CSSObject = {
  borderTop: [0, null, '1px'],
  borderTopColor: ['unset', null, 'gray.200'],
  borderLeft: ['unset', null, '1px'],
  borderLeftColor: ['unset', null, 'gray.200'],
};

export const BoxTableWrapper: CSSObject = {
  borderBottomRadius: 'lg',
  bg: ['none', 'white'],
};

export const CenterTH = () => ({
  bg: 'gray.100',
  fontWeight: 'medium',
  borderLeft: ['unset', null, '1px'],
  borderLeftColor: ['unset', null, 'gray.200'],
  borderRight: ['unset', null, '1px'],
  borderRightColor: ['unset', null, 'gray.200'],
});

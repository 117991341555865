import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'tab',
    bgGradient: 'linear(to-b, beta.400, beta.500)',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '2xs',
    px: '2',
    pb: '2px',
    borderRadius: 'md',
    color: 'rgba(0, 0, 0, 0.50)',
    sx: {
      '&&': {
        h: '24px',
      },
      '&[data-active="true"]': {
        bg: 'blackAlpha.400',
        bgImg: 'none',
      },
    },
    _hover: {
      bgGradient: 'linear(to-b, beta.500, beta.600)',
    },
  },
};
